.s-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0 2rem;
  justify-content: center;
  align-items: center;
}

.s-container > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  align-items: center;
  font-size: large;
}

.s-container > div:nth-child(1) > span {
  align-self: center;
  justify-self: center;
  text-transform: uppercase;
  font-weight: bold;
  font-style: italic;
}

.start-over {
  cursor: pointer;
}

.score-session {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  font-weight: bold;
  text-transform: uppercase;
}

.score-session > div:nth-child(1) {
  background-color: rgb(224, 87, 87);
  padding: 1rem;
  border-radius: 50%;
  color: white;
}

.score-session > div:nth-child(2) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border: 2px solid rgb(9, 211, 76);
}

.q-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 2rem;
}

.q-container > div {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.q-header {
  font-weight: bold;
  font-size: xx-large;
  text-transform: uppercase;
}

.question {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.questions {
  border: 2px solid black;
  border-radius: 5px;
  padding: 2rem;
}
