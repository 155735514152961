.header-container {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  text-transform: uppercase;
  font-size: small;
  background-color: black;
  color: white;
  padding: 1rem;
}

.Makersplace {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.Makersplace > img {
  height: 2rem;
}

.Microbit {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.Microbit > img {
  height: 2rem;
}
