.footer-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background-color: aqua;
  margin-top: 2rem;
}

.footer-container > span {
  font-weight: bold;
  text-transform: uppercase;
}
