.App {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  overflow: hidden;
}

::-webkit-scrollbar {
  display: none;
}

.stroke-text {
  color: transparent;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgb(13, 141, 163);
}
